<template>
  <div class="moreCourse">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
              ref="searchFilter"
              :searchName="'搜索课程'"
              :keywordOptions="{
                placeholder: '请输入课程名称或创建人名称',
                prop: 'keyword',
                autoName: 'course',
                autocomplete: true,
              }"
              :searchOptions="[
                {
                  name: 'el-form-item',
                  options: {
                    label: '类别',
                    prop: 'cid',
                    type: 'radio-group',
                    data: { name: 'course', keys: 'cate' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '类型',
                    prop: 'tid',
                    type: 'radio-group',
                    data: { name: 'course', keys: 'type' },
                    defaultValue: '全部',
                  },
                },
              ]"
              v-model="where"
              :total="total"
              @search="currentChange(1)"
            ></search-filter>
          </el-card>
          <el-empty
            v-if="!courseList.length"
            :description="'暂无课程'"
            class="nothing"
          ></el-empty>
          <el-row :gutter="30" style="margin-top: 40px">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="4"
              :xl="4"
              v-for="(item, index) in courseList"
              :key="index"
              class="Course-item"
            >
              <CourseItem :item="item"></CourseItem>
            </el-col>
          </el-row>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="currentChange"
              :page-size="page_size"
              :current-page="page"
              hide-on-single-page
            >
            </el-pagination>
          </div>
        </div>

        <!-- <div class="content-right">
          <Advertising></Advertising>
          <Recommend></Recommend>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { course_list } from "@/api/course.js";

import CourseItem from "@/components/CourseItem/CourseItem.vue";
import Recommend from "./components/Recommend.vue";
import Advertising from "@/components/Advertising/Advertising.vue";
import SearchFilter from "@/components/com/SearchFilter";

export default {
  components: {
    SearchFilter,
    CourseItem,
    Advertising,
    Recommend,
  },
  data() {
    return {
      where: {},
      page: 1,
      page_size: 9,
      courseList: [],
      total: 0,
      params: {},
    };
  },
  created() {
    console.log(this.$route, "this.$route.query");
    this.getCateList(this.$route.query);
    console.log("类型切换");
  },
  methods: {
    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.params };
      // console.log(this.where, "this.wherethis.where");
      // console.log('路由变化~~~~~~~~~')
    },
    // 获取课程
    getCateList(params) {
      let data = {
        ...this.where,
        ...(params || {}),
        page: this.page,
        pageSize: this.page_size,
      };
      if (data.cid) {
        // console.log("存在cid");
        course_list(data)
          .then((res) => {
            this.courseList = (res.data || {}).data;
            this.total = res.data.total;
          })
          .catch((error) => {});
      }
      // course_list({ page: this.page,
      // pageSize: this.page_size,})
      // .then((res) => {
      //   this.courseList = (res.data || {}).data;
      //   this.total=res.data.total
      // })
      // .catch((error) => {
      // });
    },
    currentChange(e) {
      this.page = e;
      // console.log(this.where, "===========");
      if (this.where.cid) {
        this.routeQueryUpdate(this.where, this.getCateList);
      } else {
        console.log("全部");
        let data = {
          ...this.where,
          page: this.page,
          pageSize: this.page_size,
        };
        course_list(data).then((res) => {
          this.courseList = (res.data || {}).data;
          this.total = res.data.total;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nothing {
  width: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  text-align: center;
  margin-top: 5%;
}
.moreCourse {
  width: 100%;
  background-color: #f5f5f5;
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      // width: 70%;
      width: 100%;
      .Course-item {
        margin-bottom: 1.8rem;
      }
    }
    .content-right {
      width: 27%;
    }
    .pagination {
      text-align: center;
      margin-top: 2rem;
    }
  }
}
</style>